<template>
  <div class="doctoralia-facility-chooser">
    <div class="loading loading-lg" v-if="loading"></div>
    <template v-if="!current">
      <p class="text-bold text-secondary">Selecione a unidade que deseja gerenciar</p>
      <facility-item
        class="facility-item"
        v-for="item in facilities"
        :key="item.id"
        :data="item"
        @click="setCurrent(item)"
      ></facility-item>
    </template>
    <facility-item class="selected" :data="current" v-else>
      <button
        class="btn btn-action btn-neutral btn-icon"
        @click="setCurrent(null)"
      ><fa-icon :icon="['fal', 'times']"></fa-icon></button>
    </facility-item>
  </div>
</template>

<script>
import FacilityItem from './FacilityItem.vue';

export default {
  components: {
    FacilityItem,
  },
  data() {
    return {
      loading: true,
      facilities: [],
      current: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const { data } = await this.$http.get('/facilities');
        this.facilities = data.items;
      } catch (e) {
        this.$toast.error(e);
      }
      this.loading = false;
    },
    setCurrent(item) {
      this.current = item;
      this.$emit('change', item);
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.doctoralia-facility-chooser {
}

.doctoralia-facility-item {
  &:not(.selected):hover {
    background-color: $gray-color-ultra-light;
    cursor: pointer;

    .facility-item-name {
      color: $primary-color;
    }
  }

  &.selected {
    border: $border-width solid $border-color-light;
    border-radius: $border-radius;

    .facility-item-name {
      color: $primary-color;
    }
  }
}
</style>
