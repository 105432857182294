<template>
  <div class="doctoralia-facility-item" @click="onClick">
    <div class="facility-item-content">
      <div class="facility-item-name">{{ data.name }}</div>
      <div class="facility-item-description">{{ data.address | address }}</div>
    </div>
    <div class="facility-item-action">
      <slot>
        <button
          class="btn btn-action btn-neutral btn-icon"
          @click="onClick"
        ><fa-icon :icon="['fal', 'chevron-right']"></fa-icon></button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    onClick() {
      this.$emit('click', this.data);
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.doctoralia-facility-item {
  align-items: center;
  border-top: $border-width solid $border-color;
  display: flex;
  padding: $layout-spacing $layout-spacing;
  &.selected {
    border: $border-width solid $border-color-light;
    border-radius: $border-radius;
    .facility-item-name {
      color: $primary-color;
      font-weight: 500;
    }
  }
  .facility-item-content {
    flex: 1 1 auto;
    margin-right: $layout-spacing-lg;
  }
  .facility-item-name {
    font-weight: 400;
  }
  .facility-item-description {
    color: $gray-color-700;
    font-size: $font-size-sm;
    padding-top: $border-width-lg;
  }
  .facility-item-info {
    color: $info-color;
    font-size: $font-size-sm;
    padding-top: $border-width-lg;
  }
  .facility-item-action {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    text-align: right;
    .btn-icon svg {
      color: $gray-color-600;
    }
    .form-select {
      width: auto;
      max-width: 10rem;
    }
    .form-switch {
      margin: 0;
      padding-left: $control-size-sm;
    }
  }
}
</style>
