<template>
  <tr>
    <td>
      <label class="form-switch m-0 pl-0">
        <input type="checkbox" :checked="selected" @change="toggle">
        <i class="form-icon"></i>
      </label>
    </td>
    <td>
      {{ professional.name }}<br>
      <small v-if="professional.specialty">{{ professional.specialty.name }}</small>
      <small v-if="professional.specialty && professional.council"> - </small>
      <small v-if="professional.council">{{ professional.council | council }}</small>
    </td>
    <td>
      <span
        class="text-large d-inline-block pt-1"
        :class="{ 'text-gray': !isActive }"
      ><fa-icon :icon="['fal', 'arrow-right']"></fa-icon></span>
    </td>
    <td>
      <select
        class="form-select"
        v-model="form.mappingId"
        :disabled="!canEdit || !!form.mappingId"
        :class="{ readonly: isActive && !!form.mappingId }"
        @change="change"
      >
        <option value="">[Selecione]</option>
        <option
          v-for="item in professionals"
          :value="item.id"
          :key="item.id"
        >{{ item.name }}</option>
      </select>
      <div v-if="form.mappingId" class="mt-2">
        <div class="form-group" v-for="item in addresses" :key="item.id">
          <label class="form-checkbox">
          <input
            type="radio"
            v-model="form.addressMappingId"
            id="address"
            :value="item.id"
            :disabled="!canEdit"
          >
            <i class="form-icon"></i>
            <span
              :class="{ 'text-gray': !isActive }"
            >{{ item.name }} - {{ item.street }}</span>
          </label>
          <template v-if="form.addressMappingId">
            <button
              class="btn btn-sm btn-info-outline"
              @click="openResourcesModal(resourceTypes.INSURANCE)"
              :disabled="!canEdit"
            >Convênios</button>
            <button
              class="btn btn-sm btn-info-outline ml-2"
              @click="openResourcesModal(resourceTypes.EXPENSE)"
              :disabled="!canEdit"
            >Procedimentos</button>
          </template>
        </div>
      </div>
    </td>
    <td>
      <button
        class="btn btn-action btn-icon"
        :class="syncButtonClasses"
        data-tooltip="Sincronizar"
        :disabled="!canSync"
        @click="sync"
      ><fa-icon :icon="['fal', 'sync']"></fa-icon></button>
      <button
        class="btn btn-action btn-primary btn-icon tooltip ml-1"
        :class="{ loading: saving }"
        data-tooltip="Salvar"
        :disabled="!dirty || !canSave"
        @click="save"
      ><fa-icon :icon="['fal', 'save']"></fa-icon></button>
    </td>

    <mapping-item-resources
      v-if="resourceModal.show"
      :show.sync="resourceModal.show"
      :type="resourceModal.type"
      :form="this.form"
      :saving="saving"
      @change="updateResourcesAndClose"
    ></mapping-item-resources>
  </tr>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AblyService from '@/services/ably';
import { clone, mergeFrom } from '@/helpers/object';
import api from '../api';
import MappingItemResources from './MappingItemResources.vue';
import { types as resourceTypes } from './mappingResources';

export default {
  props: {
    professional: {
      type: Object,
    },
  },
  components: {
    MappingItemResources,
  },
  data() {
    return {
      saving: false,
      syncing: false,
      selected: false,
      dirty: false,
      form: this.blankForm(),
      professionals: [],
      addresses: [],
      resourceModal: {
        show: false,
        type: '',
      },
      resourceTypes,
      unwatchItems: [],
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    const { realtime } = AblyService.instance(this.user.id);
    const channel = realtime.channels.get(this.eventsChannel);
    channel.unsubscribe(this.syncEventListener);
    this.unwatchItems.forEach(unwatch => unwatch());
  },
  computed: {
    ...mapState({
      user: ({ auth }) => auth.user,
    }),
    ...mapState('doctoralia', {
      data: ({ data }) => data,
    }),
    isActive() {
      return this.selected;
    },
    canEdit() {
      return this.isActive && !this.saving && !this.syncing;
    },
    canSave() {
      return !this.saving && !this.syncing;
    },
    canSync() {
      return this.isActive && !!this.form.mappingId && !this.form.synced;
    },
    eventsChannel() {
      return `${this.user.branch.id}-doctoralia-sync`;
    },
    syncButtonClasses() {
      return {
        loading: this.syncing,
        'btn-info-outline': this.canSync,
        'btn-gray-outline': !this.canSync,
        tooltip: this.canSync,
      };
    },
  },
  methods: {
    ...mapActions('doctoralia', {
      saveItem: 'saveItem',
      removeItem: 'removeItem',
    }),
    async init() {
      const found = this.data.professionals.find(({ id }) => id === this.professional.id);
      if (found) {
        this.form = mergeFrom(this.blankForm(), found);
        this.selected = true;
      }
      await this.loadProfessionals();
      if (this.form.mappingId) {
        await this.loadAddresses();
      }

      this.startWatchers();

      const { realtime } = AblyService.instance(this.user.id);
      const channel = realtime.channels.get(this.eventsChannel);
      channel.subscribe(this.syncEventListener);
    },
    startWatchers() {
      this.unwatchItems.push(
        this.$watch('form.mappingId', function formMappingId() {
          this.loadAddresses();
          this.dirty = this.selected;
        }),
      );
    },
    toggle() {
      if (!this.selected) {
        this.selected = true;
      } else {
        this.selected = false;
        this.dirty = !!this.form.mappingId;
      }
    },
    change() {
      const blankData = this.blankForm();
      delete blankData.id;
      delete blankData.mappingId;
      this.form = { ...this.form, ...blankData };
      this.dirty = true;
    },
    blankForm() {
      return {
        id: this.professional.id,
        mappingId: '',
        addressMappingId: '',
        expenses: [],
        insurances: [],
        synced: false,
      };
    },
    async save() {
      this.saving = true;
      try {
        if (this.selected) {
          await this.saveItem(clone(this.form));
        } else {
          await this.removeItem(clone(this.form));
          this.form = this.blankForm();
        }
      } catch (e) {
        this.$toast.error(e);
      }
      this.dirty = false;
      this.saving = false;
    },
    syncEventListener({ name, data }) {
      if (name === 'finish') {
        if (data.professionalId === this.form.id) {
          this.syncing = false;
          this.form.synced = true;
        }
      }
    },
    async sync() {
      this.syncing = true;
      try {
        await api.mapping.syncProfessional(this.form.id);
      } catch (e) {
        this.$toast.error(e);
      }
      // this.syncing = false;
    },
    async openResourcesModal(type) {
      this.resourceModal.type = type;
      this.resourceModal.show = true;
    },
    async updateResourcesAndClose({ type, items, postProcess }) {
      if (type === resourceTypes.INSURANCE) {
        this.form.insurances = items;
      } else if (type === resourceTypes.EXPENSE) {
        this.form.expenses = items;
      }
      await this.save();
      if (typeof postProcess === 'function') {
        await postProcess();
      }
      this.resourceModal.show = false;
    },
    async loadProfessionals() {
      try {
        const { items } = await api.professional.find();
        this.professionals = items;
      } catch (e) {
        this.$toast.error(e);
      }
    },
    async loadAddresses() {
      if (!this.form.mappingId) return;

      try {
        const { items } = await api.professional.findAddresses(this.form.mappingId);
        this.addresses = items;
      } catch (e) {
        this.$toast.error(e);
      }
    },
  },
};
</script>
