<template>
  <div class="page-container doctoralia-page">
    <portal to="page-name">Doctoralia</portal>
    <div class="card card-page">
      <div class="card-header">
        <h1 class="card-title">Doctoralia</h1>
      </div>

      <div class="card-body">
        <facility-chooser @change="setFacility"></facility-chooser>
        <template v-if="facility">
          <div class="input-group mt-2 mb-2">
            <span class="input-group-addon">Perfil Doctoralia</span>
            <select
              class="form-select"
              :class="{ saving, readonly: !!data.id }"
              v-model="mappingId"
              :disabled="data.id"
            >
              <option value="">[Selecione]</option>
              <option
                v-for="facility in facilities"
                :value="facility.id"
                :key="facility.id"
              >{{ facility.name }}</option>
            </select>
            <button
              class="btn btn-primary input-group-btn"
              :class="{ loading: loading || saving }"
              :disabled="saving || data.id"
              @click="save"
            >Salvar</button>
          </div>

          <mappings v-if="data.id"></mappings>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { resetCache } from '@/helpers/api';
import { setBranchId } from '@/plugins/http';
import FacilityChooser from './FacilityChooser.vue';
import Mappings from './Mappings.vue';
import api from '../api';

export default {
  components: {
    FacilityChooser,
    Mappings,
  },
  data() {
    return {
      loading: false,
      saving: false,
      mappingId: '',
      facility: null,
      facilities: [],
    };
  },
  beforeDestroy() {
    this.reset();
  },
  computed: {
    ...mapState('doctoralia', {
      data: ({ data }) => data,
    }),
  },
  methods: {
    ...mapActions('doctoralia', {
      loadData: 'load',
      saveData: 'save',
    }),
    async save() {
      this.saving = true;
      try {
        await this.saveData({
          mappingId: this.mappingId,
        });
      } catch (e) {
        this.$toast.error(e);
      }
      this.saving = false;
    },
    setFacility(value) {
      resetCache();
      this.facility = value || null;
      if (this.facility !== null) {
        this.facility = value;
        setBranchId(this.facility.entityId);
        this.load();
      } else {
        this.reset();
      }
    },
    async load() {
      this.loading = true;
      await Promise.all([
        this.loadMapping(),
        this.loadFacilities(),
      ]);
      this.loading = false;
    },
    reset() {
      setBranchId(null);
      resetCache();
      this.facilities = [];
      this.facility = null;
    },
    async loadMapping() {
      try {
        await this.loadData();
        this.mappingId = this.data.mappingId;
      } catch (e) {
        this.$toast.error(e);
      }
    },
    async loadFacilities() {
      try {
        const { items } = await api.facility.find();
        this.facilities = items;
      } catch (e) {
        this.$toast.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.doctoralia-page {
}
</style>
