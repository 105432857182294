export function first(items, defaultValue = null) {
  return Array.isArray(items) && items.length > 0
    ? items[0]
    : defaultValue;
}

export function last(items, defaultValue = null) {
  return Array.isArray(items) && items.length > 0
    ? items[items.length - 1]
    : defaultValue;
}

export function ifNull(items, defaultValue = []) {
  return Array.isArray(items) ? items : defaultValue;
}

export function hasItems(items) {
  return Array.isArray(items) && items.length > 0;
}
