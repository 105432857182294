export const INSURANCE = 'insurance';

export const EXPENSE = 'expense';

export const types = {
  INSURANCE,
  EXPENSE,
};

export default {
  [INSURANCE]: {
    title: 'Convênios',
  },
  [EXPENSE]: {
    title: 'Procedimentos e serviços',
  },
};
