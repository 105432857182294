<template>
  <div>
    <div class="loading loading-lg" v-if="loading"></div>
    <table class="doctoralia-mappings table" v-else>
      <colgroup>
        <col style="width:2.4rem">
        <col style="width:35%">
        <col style="width:1.4rem">
        <col style="">
        <col style="width:3.8rem;">
      </colgroup>
      <tbody>
      <mapping-item
        v-for="item in items"
        :key="item.key"
        :professional="item.professional"
      ></mapping-item>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { first } from '@/helpers/array';
import * as professionalApi from '../../professional/api';
import MappingItem from './MappingItem.vue';

export default {
  components: {
    MappingItem,
  },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapState('doctoralia', {
      data: ({ data }) => data,
    }),
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const { items } = await professionalApi.allActive();
        this.items = items.map(item => ({
          key: item.id,
          professional: {
            id: item.id,
            name: item.name,
            specialty: first(item.professional.specialties),
            council: first((
              item.professional.councils.filter(({ record }) => !!record)
            )),
          },
        }));
      } catch (e) {
        this.$toast.error(e);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

.doctoralia-mappings {
  td {
    vertical-align: top;
  }
}
</style>
