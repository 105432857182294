<template>
  <dx-modal
    id="doctoralia-mapping-resources-modal"
    :value="show"
    :title="title"
    @input="close"
    :closable="false"
  >
    <div
      v-if="loading"
      class="loading loading-lg"
      style="padding: 2rem"
    ></div>
    <template v-else>
      <div class="form-group">
        <dx-autocomplete
          id="new-resource"
          v-model="newResource.model"
          :source="newResource.sourceHandler"
          label="name"
          track-by="id"
          :debounce="100"
          :max-height="186"
          placeholder="Buscar...">
          <template v-slot:action>
            <button
              class="btn btn-info input-group-btn btn-icon"
              :class="{ loading: newResource.processing }"
              :disabled="!newResource.model"
              @click="createNewResource"
            ><fa-icon :icon="['fal', 'plus']" /></button>
          </template>
        </dx-autocomplete>
      </div>

      <table class="table">
        <thead>
        <tr>
          <th>Doctoralia</th>
          <th style="width: 1.5rem"></th>
          <th style="width: 40%">Stenci</th>
          <th style="width: 2.2rem"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>
            <span v-if="!isDeleted(item)">{{ item.name }}</span>
            <s class="text-gray" v-else>{{ item.name }}</s>
          </td>
          <td class="text-center">
            <span
              class="text-large"
              :class="{ 'text-gray': !isActive(item) }"
            ><fa-icon :icon="['fal', 'arrow-right']"></fa-icon></span>
          </td>
          <td>
            <select
              class="form-select"
              v-model="item.form.id"
              :disabled="!isActive(item)"
            >
              <option value="">[Selecione]</option>
              <option
                v-for="option in stenciItems"
                :value="option.id"
                :key="option.id"
              >{{ option.name }}</option>
            </select>
          </td>
          <td>
            <button
              class="btn btn-action btn-icon"
              :class="isDeleted(item) ? 'btn-gray' : 'btn-error'"
              @click="item.action = 'delete'"
              :disabled="isDeleted(item)"
            ><fa-icon :icon="['fal', 'times']"></fa-icon></button>
          </td>
        </tr>
        </tbody>
      </table>
    </template>

    <template v-slot:footer>
      <button class="btn btn-gray" @click="close">Cancelar</button>
      <button
        class="btn btn-primary ml-2"
        :class="{ loading: isBusy }"
        @click="change"
        :disabled="isBusy"
      >Salvar</button>
    </template>
  </dx-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { clone } from '@/helpers/object';
import * as insuranceApi from '@/app/insurance/api';
import api from '@/app/doctoralia/api';
import * as expenseApi from '@/app/procedure/api';
import mappingResources, { types } from './mappingResources';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    form: {
      type: Object,
    },
    saving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      newResource: {
        processing: false,
        model: null,
        items: null,
        sourceHandler: async () => [],
        createHandler: async () => [],
      },
      mappings: [],
      stenciItems: [],
      doctoraliaItems: [],
      items: [],
      removeDoctoraliaResources: null,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    title() {
      return mappingResources[this.type].title;
    },
    isBusy() {
      return this.loading || this.saving;
    },
  },
  methods: {
    ...mapActions('doctoralia', {
      saveItem: 'saveItem',
    }),
    async init() {
      this.loading = true;
      try {
        if (this.type === types.INSURANCE) {
          this.mappings = this.form.insurances;
          this.stenciItems = await this.fetchInsurances();
          this.doctoraliaItems = await this.fetchDoctoraliaInsurances();
          this.newResource.sourceHandler = this.searchNewInsurance;
          this.newResource.createHandler = this.createNewInsurance;
          this.removeDoctoraliaResources = this.removeDoctoraliaInsurances;
        } else if (this.type === types.EXPENSE) {
          this.mappings = this.form.expenses;
          this.stenciItems = await this.fetchExpenses();
          this.doctoraliaItems = await this.fetchDoctoraliaExpenses();
          this.newResource.sourceHandler = this.searchNewExpense;
          this.newResource.createHandler = this.createNewExpense;
          this.removeDoctoraliaResources = this.removeDoctoraliaExpenses;
        }
        this.prepareItems();
      } catch (e) {
        this.$toast.error(e);
      }
      this.loading = false;
    },
    close() {
      this.$emit('update:show', false);
    },
    async save() {
      this.saving = true;
      try {
        await this.saveItem(clone(this.form));
      } catch (e) {
        this.$toast.error(e);
      }
      this.saving = false;
    },
    change() {
      const data = {
        type: this.type,
        items: [],
        postProcess: null,
      };

      data.postProcess = this.removeDoctoraliaResources((
        this.items.filter(this.isDeleted).map(({ form }) => form.mappingId)
      ));

      data.items = this.items
        .filter(item => item.form.id && !this.isDeleted(item))
        .map(({ form }) => form);

      this.$emit('change', data);
    },
    addItem(item, isNew = false) {
      const found = this.mappings.find(({ mappingId }) => mappingId === item.id);
      const itemData = {
        action: found ? 'save' : '',
        ...item,
        form: {
          id: found ? found.id : '',
          mappingId: item.id,
        },
      };
      if (isNew) {
        this.items.unshift(itemData);
      } else {
        this.items.push(itemData);
      }
    },
    prepareItems() {
      this.doctoraliaItems.forEach((item) => {
        this.addItem(item);
      });
    },
    isDeleted(item) {
      return item.action === 'delete';
    },
    isActive(item) {
      return !this.isDeleted(item);
    },
    async createNewResource() {
      this.newResource.processing = true;
      try {
        const data = await this.newResource.createHandler(this.newResource.model);
        this.addItem(data, true);
        this.newResource.model = null;
      } catch (e) {
        this.$toast.error(e);
      }
      this.newResource.processing = false;
    },
    async removeDoctoraliaInsurances(ids) {
      const promises = ids.map(id => (
        api.professional.deleteAddressInsurance(
          this.form.mappingId,
          this.form.addressMappingId,
          id,
        )
      ));

      return Promise.all(promises);
    },
    async removeDoctoraliaExpenses(ids) {
      const promises = ids.map(id => (
        api.professional.deleteAddressExpense(
          this.form.mappingId,
          this.form.addressMappingId,
          id,
        )
      ));

      return Promise.all(promises);
    },
    async searchNewInsurance(search) {
      if (this.newResource.items === null) {
        const { items } = await api.insurance.find();
        this.newResource.items = items;
      }

      return this.newResource.items.filter(({ name }) => (
        new RegExp(search, 'i').test(name)
      ));
    },
    async searchNewExpense(search) {
      if (this.newResource.items === null) {
        const { items } = await api.expense.find();
        this.newResource.items = items;
      }

      return this.newResource.items.filter(({ name }) => (
        new RegExp(search, 'i').test(name)
      ));
    },
    async createNewInsurance(item) {
      return api.professional.createAddressInsurance(
        this.form.mappingId,
        this.form.addressMappingId,
        { insuranceId: item.id },
      );
    },
    async createNewExpense(item) {
      return api.professional.createAddressExpense(
        this.form.mappingId,
        this.form.addressMappingId,
        { expenseId: item.id },
      );
    },
    async fetchInsurances() {
      const { items } = await insuranceApi.find({ limit: 0 });
      return items.filter(({ name }) => !/Particular/i.test(name));
    },
    async fetchDoctoraliaInsurances() {
      const { items } = await api.professional.findAddressInsurances(
        this.form.mappingId,
        this.form.addressMappingId,
      );
      return items;
    },
    async fetchExpenses() {
      const { items } = await expenseApi.find({
        types: 'consultation,session',
        limit: 0,
      });
      return items;
    },
    async fetchDoctoraliaExpenses() {
      const { items } = await api.professional.findAddressExpenses(
        this.form.mappingId,
        this.form.addressMappingId,
      );
      return items;
    },
  },
};
</script>

<style lang="scss">
#doctoralia-mapping-resources-modal {
  .modal-body {
    min-height: 250px;
  }
}
</style>
